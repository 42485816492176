<template>
  <v-card class="login-card">
    <div class="layout column align-center">
      <img src="/static/light.png" alt="Safemod" height="50" class="mb-5" />
      <h3 class="flex">Trocar Senha</h3>
    </div>
    <v-card-text v-if="!success">
      <v-form>
        <v-text-field
          append-icon="email"
          name="email"
          placeholder="E-mail"
          type="email"
          v-model="email"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-form>
      <div class="login-btn">
        <v-btn
          block
          color="primary"
          class="btn_gradient"
          @click="reset"
          :loading="loading"
          >Reset Password</v-btn
        >
      </div>
      <v-alert v-model="error" transition="scale-transition" type="error">
        {{ errorMessage }}
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <p style="text-align: center">
        {{ message }}
      </p>
    </v-card-text>

    <v-card-text class="flex">
      <p style="text-align: center">
        <a @click="redirectToLogin" class="register_link">Login</a>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},
  data: () => ({
    loading: false,
    success: false,
    email: '',
    error: false,
    errorMessage: 'Formulário inválido',
    rules: {
      required: (value) => !!value || 'Campo Obrigatório.',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'E-mail Inválido';
      }
    },
    message:
      'Verifique sua caixa de e-mail para as próximas etapas. Se você não receber um e-mail e ele não estiver na sua pasta de spam, isso pode significar que você foi cadastrado com um endereço diferente.'
  }),
  async mounted() {},
  methods: {
    ...mapActions({
      _reset: 'reset'
    }),
    async reset() {
      this.error = false;
      try {
        await this._reset({
          email: this.email,
          resetURL: location.href.replace('reset', 'reset-password')
        });
        this.success = true;
      } catch (err) {
        this.error = true;
        this.errorMessage = 'Something went wrong, please try again.';
      }
    },

    redirectToLogin() {
      this.$router.push({ path: 'login' });
    }
  }
};
</script>
<style scoped lang="css">
input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.login-card {
  max-width: 450px;
  margin: 0 auto;
  box-shadow: none;
}
.logo {
  max-width: 300px;
  margin: 0 auto;
  height: auto;
}
.login-card h1 {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  margin-top: 50px;
}
.login-card .form-control {
  font-size: 20px;
}
p {
  color: #000;
  font-weight: 500;
}

@media (max-width: 1440px) {
  .logo {
    max-width: 200px;
  }
  .login-card h1 {
    font-size: 20px;
    margin-top: 30px;
  }
  .login-card .form-control {
    font-size: 16px;
  }
}
</style>
